.toast {
  font-size: 16px !important;
}
.alert {
  display: flex;
  margin-top: 30px;
  margin-bottom: 8px;
}
.btn {
  margin-right: 8px !important;
  height: 36px;
  padding: 8px 15px !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: bold !important;
}
.btn-confirm {
  background-color: #21ba45 !important;
}
